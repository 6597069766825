import axios from "axios";

// Função para compactar uma imagem usando tela
const compressImage = (imageFile, compressionRatio = 0.7) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(imageFile);
    reader.onload = () => {
      const img = new Image();
      img.src = reader.result;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // Calcule as novas dimensões com base na taxa de compressão
        const maxWidth = 500; // Ajuste esse valor conforme necessário para seu caso de uso
        const maxHeight = 500; // Ajuste esse valor conforme necessário para seu caso de uso

        let width = img.width;
        let height = img.height;

        if (width > height && width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        } else if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }

        canvas.width = width;
        canvas.height = height;

        // Desenhe a imagem na tela com as novas dimensões
        ctx.drawImage(img, 0, 0, width, height);

        // Obtenha o URL de dados compactados da tela
        const compressedDataUrl = canvas.toDataURL("image/jpeg", compressionRatio);

        // Resolva a promessa com o URL de dados compactados
        resolve(compressedDataUrl);
      };
      img.onerror = (error) => reject(error);
    };
    reader.onerror = (error) => reject(error);
  });
};

// Função para converter arquivos de imagem para base64(com compressão)
const convertImagesToBase64 = async (imageFiles) => {
  try {
    const base64Images = [];

    for (const file of imageFiles) {
      const base64Image = await compressImage(file, 0.7); // Ajuste a compressãoRatio conforme necessário
      base64Images.push({ path: base64Image });
    }

    return base64Images;

  } catch (error) {
    console.error("Error converting images to base64:", error);
    return error
  }
};

// Função para postar dados no servidor
export const postDataService = async ({ assunto, categoria, descricao, listmail, files }) => {

  const resBase64 = await convertImagesToBase64(files);

  if(resBase64.type === 'error'){
    return undefined
  }
  
  const path = '/mail/send'
  const options = {
    method: "POST",
    /*url: 'http://localhost:3001/mail/send',*/
    url: process.env.REACT_APP_URL_API_PROD + path,
    /* url: 'https://ouvidoria-api-prod-3qo7y2nenq-ue.a.run.app/mail/send',*/
    headers: { "Content-Type": "application/json" },
    data: {
      assunto: assunto,
      categoria: categoria,
      descricao: descricao,
      mailist: listmail,
      /*  mailist: ["dhiego.lira@fbrdigital.com.br"], */
      files: resBase64,
    },
  };

  const response = await axios.request(options).then(function (response) {
    return response
  }).catch(function (error) {
    return error.response
  })

  return response
};





