import React, { createContext, useEffect, useState } from "react";
import { postAuthService, getUserLocalStorage, setuserLocalStorage } from "../services/PostAuthService";

export const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const user = getUserLocalStorage();
        if (user) {
            setUser(user)
        }
    }, [])

    const authenticate = async (email, password) => {
        const res = await postAuthService(email, password);
        if (res.status === 401) {
            return res;
        }
        const payload = { token: res.data.token }
        setUser(payload);
        setuserLocalStorage(payload);
        return res;
    }

    const logOut = () => {
        setUser(null);
        setuserLocalStorage(null);
    }
    return (
        <AuthContext.Provider value={{ ...user, authenticate, logOut }}>
            {children}
        </AuthContext.Provider>
    )
}
