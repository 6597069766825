import React from "react";
import { useLocation, Navigate } from "react-router-dom";
import { getUserLocalStorage } from "../services/PostAuthService";

const ProtectedLayout = ({ children }) => {
    const auth = getUserLocalStorage();
    const location = useLocation();
    //console.log(auth.token)
    if (!auth.token) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
}

export default ProtectedLayout;