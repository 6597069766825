import React, { useState } from 'react';
import OuvidoriaLogo from '../style/img/OuvidoriaLogo.png';
import { useForm } from "react-hook-form";
import { useAuth } from '../hook/UseAuth';
import { useNavigate } from "react-router-dom";


function Login() {

    const { register, handleSubmit, formState: { errors } } = useForm();
    const [isLogin, setIsLogin] = useState(false)
    const [loginError, setLoginError] = useState(false);
    const navigate = useNavigate();
    const auth = useAuth()

    const loginSubmit = async (e) => {
        setIsLogin(true)
        const loginRes = await auth.authenticate(e.email, e.password)
        if (loginRes.status === 200) {
            navigate("/home");
            setIsLogin(false)
        } else if (loginRes.status === 401) {
            setLoginError(true)
            setIsLogin(false)
        }
    }

    const renderErrors = (errors) => {
        if (errors.email && errors.password) {
            return (
                <div className="row text-center">
                    <p className="error-msg">Digite seu email e senha</p>
                </div>
            )
        } else if (errors.email && !errors.password) {
            return (
                <div className="row text-center">
                    <p className="error-msg">Digite seu email</p>
                </div>
            )
        } else if (!errors.email && errors.password) {
            return (
                <div className="row text-center">
                    <p className="error-msg">Digite sua senha</p>
                </div>
            )
        }
    }

    return (
        <div className="background vw-100 vh-100 d-flex justify-content-center align-items-center">
            {isLogin && <div className="loading-login d-flex vh-100 vw-100 justify-content-center align-items-center">
                <div className="spinner-border text-warning justify-content-center align-items-center" role="status">
                    <span className="visually-hidden"></span>
                </div>
            </div>}
            <div className='row form-login p-5 d-flex flex-column text-center'>
                <div class="row mb-5">
                    <img alt="logo fbr" src={OuvidoriaLogo}></img>
                </div>
                <form onSubmit={handleSubmit(loginSubmit)}>
                    <div className="row mb-3">
                        <div className="col-sm-12">
                            <input className="input-login form-control" type="email" placeholder='Email' {...register("email", { required: true })}></input>
                        </div>
                        <span className="span-home" >{errors.email?.message}</span>
                    </div>
                    <div className="row mb-3">
                        <div className="col-sm-12">
                            <input className="input-login form-control" type="password" placeholder='Senha' {...register("password", { required: true})}></input>
                        </div>
                        <span className="span-home" >{errors.password?.message}</span>
                    </div>
                    <div className="row mt-3 mb-3 text-center">
                        {errors && renderErrors(errors)}
                        {loginError &&
                            <div className="row text-center">
                                <p className="error-msg">Senha ou usuário inválido</p>
                            </div>}

                    </div>
                    <div>
                        <button type="submit" className="btn-login p-2">Entrar</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Login;
