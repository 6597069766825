import axios from "axios";

const path = "/auth/login"

export const postAuthService = async (email, password) => {

    const options = {
        method: 'POST',
        url: process.env.REACT_APP_URL_API_PROD + path,
        headers: { 'Content-Type': 'application/json' },
        data: { email, password }
    };

    const res = axios.request(options).then(function (response) {
        return response;
    }).catch(function (error) {
        if (error.response.status === 401) {
            return error.response
        }
    });
    return res;
}

export function setuserLocalStorage(user) {
    localStorage.setItem('u', JSON.stringify(user));
}

export function getUserLocalStorage() {
    const json = localStorage.getItem('u');

    if (!json) {
        return null;
    }
    const user = JSON.parse(json);
    return user;
}
