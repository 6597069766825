import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './pages/LoginPage';
import Home from './pages/HomePage';
import { AuthProvider } from './context/AuthContext';
import ProtectedLayout from './layouts/ProtectedLayout';


function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route path="/login" element={<Login />}></Route>

          <Route path="/home" element={
            <ProtectedLayout>
              <Home />
            </ProtectedLayout>
          }></Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>

  );
}

export default App;
