import baseText from "../style/img/logo-ouvi.png";
import footer from "../style/img/footer.png";
import { useForm } from "react-hook-form";
import React, { useState } from "react";
import ReactQuill from "react-quill";
import { useDropzone } from "react-dropzone";
import { MdCloudUpload } from 'react-icons/md';
import { LiaFileSolid } from 'react-icons/lia';
import { PiTrash } from 'react-icons/pi';
import { postDataService } from "../services/PostDataService";


function Home() {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [errorDescripiton, setErrorDescripiton] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [emailIsSend, setEmailIsSend] = useState(false);
  const [value, setValue] = useState("");
  const [files, setFiles] = useState([]);
  const [feedBackVisibily, setFeedBackVisibily] = useState('none');
  const [isLoding, setIsLogin] = useState(false);


  const onSubmit = async (data) => {

    setIsLogin(true)
    if (value === "") {
      setErrorDescripiton(true);
    } else {
      setErrorDescripiton(false);
      data.descricao = value;
      data.files = files;
      const res = await postDataService(data)
      if (res === undefined || res.status !== 200) {
        setAlertType('danger')
        setFeedBackVisibily('flex')
        setEmailIsSend(true)
        setIsLogin(false)

      } else if (res.status === 200) {
        setAlertType('success')
        setFeedBackVisibily('flex')
        setEmailIsSend(true)
        setIsLogin(false)
      }
    }
  };

  const feedbackRender = (type) => {
    switch (type) {
      case 'success':
        return (
          <div key='uniquekey' className={"alert alert-home alert-success"} role="alert" style={{ display: feedBackVisibily }}>
            <div className="justify-content-end text-end">
              <button type="button" class="btn-close" onClick={() => { setFeedBackVisibily('none'); setTimeout(() => { window.location.reload(false) }, 1100) }} ></button>
              <p className="m-0 me-3">Seu email foi enviado com sucesso!</p>
            </div>
          </div>
        )
      case 'danger':
        return (
          <div key='uniquekey' className={"alert alert-home alert-danger"} role="alert" style={{ display: feedBackVisibily }}>
            <div className="justify-content-end">
              <button type="button" class="btn-close" onClick={() => setFeedBackVisibily('none')} ></button>
              <p className="m-0">Falha ao enviar o email, veja o que pode ter acontecido:</p>
              <ul className="mt-2 mb-0">
                <li>Falha na comunicação entre serviços;</li>
                <li>Verifique se os campos foram preenchidos corretamente;</li>
                <li>Somente imagens .JPG .JPEG ou .PNG são aceitos.</li>
              </ul>
            </div>
          </div>
        )
      default:
        break;
    }
  }


  // Editor

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ font: [] }],
      ["bold", "italic", "underline", "strike"],
      [
        { list: "ordered" },
        { list: "bullet" },
      ],
    ],
  };

  /* Dropzone - campo de upload */

  const removeFile = (files) => {
    const updatedFiles = [...files];
    updatedFiles.splice(files, 1);
    setFiles(updatedFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    multiple: true,
    onDrop: (acceptedFiles) => {
      setFiles(acceptedFiles);
    },
  }
  );

  return (
    <>
      <div className="background-home vh-100">
        {isLoding && (
          <div className="loading-login d-flex justify-content-center align-items-center">
            <div className="spinner-border text-warning" role="status">
              <span className="visually-hidden"></span>
            </div>
          </div>
        )}

        <div className="row vh-100 m-0 ">
          <div className="col-md-6 img-home d-flex flex-column justify-content-center align-items-center">
            <div className="img">
              <img src={baseText} alt="ouvidoria home" />
            </div>
            <p className="text-img text-center">
              <strong className="text-strong">Olá, seja bem-vindo(a) ao Canal da Ouvidoria da FBR Digital.</strong><br /><br />
              Este é um ambiente 100% seguro e privado,
              com o propósito de promover segurança psicológica aos colaboradores da FBR Digital, <br />
              com espaço para relatar acontecimentos <br />
              diários que gerem desconfortos, solicitações, elogios, reclamações e/ou sugestões.
              <br /><br />
              <strong className="text-strong">Estamos atentos a sempre ouvir você!</strong>
            </p>
            <div>
              <p className="footer m-0 pt-5">Desenvolvido por:</p>
              <img className="img-footer" src={footer} alt="ouvidoria home" />
            </div>
          </div>

          <div className="col-md-6 form-home d-flex flex-column justify-content-center align-items-end">
            <form className="w-100 d-flex flex-column justify-content-center align-items-end" onSubmit={handleSubmit(onSubmit)}>
              {emailIsSend && feedbackRender(alertType)}
              <div className="formulario">
                <label htmlFor="exampleFormControlInput1" className="label-home">Categoria</label>
                <select className="input-home w-100 p-2" aria-label="Default select example" {...register("categoria", { required: "Campo obrigatório!" })}>
                  <option value="" selected> Selecione a categoria...</option>
                  <option value="Sugestão">Sugestão</option>
                  <option value="Elogio">Elogio</option>
                  <option value="Solicitação">Solicitação</option>
                  <option value="Denúncias">Denúncias</option>
                  <optgroup label="Reclamação">
                    <option value="Reclamacao: Descumprimento de leis e regras trabalhistas">Descumprimento de leis e regras trabalhistas</option>
                    <option value="Reclamacao: Abuso de poder">Abuso de poder</option>
                    <option value="Reclamacao: Assédio moral e/ou sexual">Assédio moral e/ou sexual</option>
                    <option value="Reclamacao: Desentendimento entre líderes e liderados">Desentendimento entre líderes e liderados</option>
                    <option value="Reclamacao: Atos racistas ou homofóbicos">Atos racistas ou homofóbicos</option>
                    <option value="reclamacao">Reclamação em geral</option>
                  </optgroup>
                </select>
                <span className="span-home">{errors.categoria?.message}</span>

                <div className="mt-2">
                  <label htmlFor="exampleFormControlInput1" className="label-home">Assunto</label>
                </div>
                <input type="text" className="input-home p-2 w-100" {...register("assunto", { required: "Campo obrigatório!" })}></input>
                <span className="span-home">{errors.assunto?.message}</span>

                <div className="mt-2">
                  <label htmlFor="exampleFormControlTextarea1" className="label-home">Descrição</label>
                </div>
                <div className="div-editor text-area vh-50 editor" value="errorEdit" {...register("descricao")}>
                  <ReactQuill theme="snow" value={value} onChange={setValue} modules={modules} />
                </div>
                {errorDescripiton && (<span className="span-home">Campo obrigatório!</span>)}

                <div className="pt-3">
                  <p className="m-0 text"><small>*Somente envie imagens .jpg, jpeg ou png</small></p>
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input id="imgUpload" {...getInputProps()} {...register("files")} />
                    <button className="btn-upload w-100 mt-2" type="button">
                      <MdCloudUpload className="svg" />
                      <p className="text-upload d-flex justify-content-center align-items-center text-center">Upload de imagens</p>
                    </button>
                  </div>
                  {files.length > 0 && (
                    <div className="files">
                      <p className="p-upload mb-0 p-0 justify-content-center align-items-center text-center"></p>
                      <ul className="list-group">
                        {files.map((file) => (
                          <li className="paragrafo-upload list-group-item list-group-item-action p-1" key={file.name}>
                            <LiaFileSolid className="svvg" /> {file.name} - {file.size} bytes
                            <button className="close-btn" onClick={() => removeFile(files)}><PiTrash className="remove-svg" /></button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                <label for="exampleFormControlInput1" className="label-home pt-2 pb-1">PARA QUEM ENVIAR?</label>
                <div className="d-flex">
                  <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked1" value="ascanio@fbrdigital.com.br" defaultChecked {...register("listmail", { required: 'Campo obrigatório!' })}></input>
                    <label className="form-check-label pe-5" >Ascânio França</label>
                  </div>
                  <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked2" value="thiago@fbrdigital.com.br" defaultChecked {...register("listmail", { required: 'Campo obrigatório!' })}></input>
                    <label className="form-check-label" >Thiago Carvalho </label>
                  </div>
                </div>
                <span className="span-home">{errors.listmail?.message}</span>
                <div className="pt-4 text-center">
                  <button type="submit" className="btn-home btnEnviar p-2" >ENVIAR</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

    </>);
}
export default Home;
